'use client';

import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';
import { cn } from '@/lib/utils';
const Popover = PopoverPrimitive.Root;
const PopoverTrigger = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger> & {
  disabled?: boolean;
}>(({
  disabled,
  ...props
}, ref) => <PopoverPrimitive.Trigger ref={ref} {...props} onClick={event => {
  if (disabled) {
    event.preventDefault();
    event.stopPropagation();
  } else if (props.onClick) {
    props.onClick(event);
  }
}} aria-disabled={disabled} />);
PopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName;
const PopoverContent = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
  showArrow?: boolean;
}>(({
  className,
  align = 'center',
  sideOffset = 4,
  showArrow = false,
  ...props
}, ref) => <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content ref={ref} align={align} sideOffset={showArrow ? sideOffset + 8 : sideOffset} className={cn('z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2', className)} {...props}>
        {props.children}
        {showArrow && <PopoverPrimitive.Arrow className={cn('fill-popover border-r border-b border-border rotate-45', 'size-2 -translate-y-1')} style={{
      position: 'absolute',
      background: 'hsl(var(--popover))'
    }} />}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
export { Popover, PopoverTrigger, PopoverContent };