'use client';

import * as SwitchPrimitives from '@radix-ui/react-switch';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '@/lib/utils';
const switchVariants = cva('peer inline-flex shrink-0 cursor-pointer items-center border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring/30 focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50', {
  variants: {
    variant: {
      default: 'data-[state=checked]:bg-primary/20 data-[state=unchecked]:bg-secondary',
      alternativePrimary: 'bg-secondary'
    },
    radius: {
      default: 'rounded-full',
      squared: 'rounded'
    },
    size: {
      default: 'h-6 w-11',
      lg: 'h-8 w-14'
    }
  },
  defaultVariants: {
    variant: 'default',
    radius: 'default',
    size: 'default'
  }
});
const switchThumbVariants = cva('pointer-events-none flex items-center justify-center shadow-lg ring-0 transition-transform', {
  variants: {
    variant: {
      default: 'data-[state=checked]:bg-primary data-[state=unchecked]:bg-background',
      alternativePrimary: 'data-[state=checked]:bg-primary data-[state=unchecked]:bg-alternative'
    },
    radius: {
      default: 'rounded-full',
      squared: 'rounded'
    },
    size: {
      default: 'size-5 m-0.5 data-[state=checked]:translate-x-5',
      lg: 'size-6 m-0.5 data-[state=checked]:translate-x-6'
    }
  },
  defaultVariants: {
    variant: 'default',
    radius: 'default',
    size: 'default'
  }
});
interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>, VariantProps<typeof switchVariants> {
  thumbIcon?: React.ReactNode;
}
const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(({
  className,
  thumbIcon,
  variant,
  radius,
  size,
  ...props
}, ref) => <SwitchPrimitives.Root className={cn(switchVariants({
  variant,
  radius,
  size,
  className
}))} {...props} ref={ref}>
    <SwitchPrimitives.Thumb className={cn(switchThumbVariants({
    variant,
    radius,
    size
  }))}>
      {thumbIcon && <div className="size-3 text-primary-foreground">{thumbIcon}</div>}
    </SwitchPrimitives.Thumb>
  </SwitchPrimitives.Root>);
Switch.displayName = SwitchPrimitives.Root.displayName;
export { Switch, switchVariants };