import * as React from 'react';
import { cn } from '@/lib/utils';
export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'default' | 'sm' | 'lg';
  variant?: 'default' | 'ghost';
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  type,
  size = 'default',
  variant = 'default',
  ...props
}, ref) => {
  return <input type={type} className={cn('flex rounded-md', 'placeholder:text-muted-foreground', {
    'w-full border border-border px-3 py-2': variant === 'default',
    'bg-input': variant === 'default',
    'border-0 bg-transparent p-0': variant === 'ghost',
    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-background focus-visible:ring-ring/30 focus-visible:ring-offset-2': variant === 'default'
  }, 'disabled:cursor-not-allowed disabled:opacity-50', {
    'h-10': size === 'default',
    'h-8': size === 'sm',
    'h-12': size === 'lg'
  }, className)} ref={ref} {...props} />;
});
Input.displayName = 'Input';
export { Input };