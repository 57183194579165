'use client';

import * as LabelPrimitive from '@radix-ui/react-label';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '@/lib/utils';
const labelVariants = cva('text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-muted-foreground');
const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants> & {
  disabled?: boolean;
}>(({
  className,
  disabled,
  ...props
}, ref) => <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), disabled && 'opacity-50', className)} {...props} />);
Label.displayName = LabelPrimitive.Root.displayName;
export { Label };